<template>
    <div id="footer">
        <div class="footer-container">
            <div class="logos-container">
                <router-link to="/">
                    <img class="logo wp-logo" src="/images/logo-wp.svg" alt="SW">
                </router-link>
                <router-link to="/">
                    <img class="logo" src="/images/logo-tbhe.svg" alt="TBHE">
                </router-link>
            </div>
            <div class="footer-content">
                <div class="footer-menu">
                    <div class="menu-items">
                        <router-link v-for="item in menuItems" :key="item.label" :to="item.link" @click.native="linkClick">
                            {{ item.label }}
                        </router-link>
                    </div>
                    <div class="social-media-container">
                        <p>
                            Follow
                        </p>
                        <div class="social-medias">
                            <a v-for="item in socialMediaItems" :key="item.icon" v-bind:href="item.url" target="_blank">
                                <img :src="`/images/icon/social-${item.icon}.svg`" :alt="item.icon" srcset="">
                            </a>
                        </div>
                    </div>
                </div>
                <div class="credits">
                    <p class="desktop-only">
                        COPYRIGHT © {{ new Date().getFullYear()}} WALTERS | PLAXEN • SHAUNA WALTERS DRE# 01728600 • NICOLE PLAXEN DRE# 01772151
                        <br>
                        Website by 
                        <a href="https://www.austinrobbins.com/" target="_blank" rel="noopener noreferrer">
                            AUSTIN ROBBINS AGENCY
                        </a>
                    </p>
                    <p class="mobile-only">
                        COPYRIGHT © {{ new Date().getFullYear()}} WALTERS | PLAXEN • SHAUNA WALTERS DRE# 01728600 • NICOLE PLAXEN DRE# 01772151
                        <br>
                        Website by 
                        <a href="https://www.austinrobbins.com/" target="_blank" rel="noopener noreferrer">
                            AUSTIN ROBBINS AGENCY
                        </a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "FooterContent",
    data(){
        return {
            menuItems: [
              {
                  label: "About",
                  link: "/about"
              },
              {
                  label: "Listings",
                  link: "/properties"
              },
              {
                  label: "Press",
                  link: "/press"
              },
              {
                  label: "Team",
                  link: "/team"
              },
              {
                  label: "Contact",
                  link: "/contact"
              },
          ],
          socialMediaItems: [
            {
                icon: 'instagram',
                url:"https://www.instagram.com/",
            },
            {
                icon: 'linkedin',
                url:" https://www.linkedin.com/",
            },
            {
                icon: 'facebook',
                url:"https://www.facebook.com/",
            },
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
#footer{
    background: #000;
    padding: 60px 0;
}
.footer-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.logos-container{
    display: flex;
    margin-left: 50px;
    .logo{
        height: 50px;
        margin-right: 20px;
    }
}
.footer-content{
    display: flex;
    flex-direction: column;
    color: #fff;
    margin-right: 50px;
}

.footer-menu{
    display: flex;
    align-items: center;
}

.menu-items{
    display: flex;
    align-items: center;
    a{
        display: block;
        font-weight: 400;
        font-size: 19px;
        line-height: 23px;
        letter-spacing: 0.1em;
        color: #FFFFFF;
        text-transform: uppercase;
        text-decoration: none;
        margin-right: 20px
    }
}

.social-media-container{
    display: flex;
    align-items: center;
    p{
        font-family: scandia-web, sans-serif;
        font-weight: 400;
        font-size: 19px;
        line-height: 23px;
        letter-spacing: 0.1em;
        color: #FFFFFF;
        text-transform: uppercase;
    }
}
.social-medias{
    a{
        margin-left: 10px
    }
}

.credits{
    p{
        font-weight: 400;
        font-size: 10px;
        line-height: 27px;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        color: #686868;
    }
    a{
        color: #ddd;
        &:hover{
            color: #fff;
        }
    }
}

@media (max-width: $tablet) {
    .footer-container{
        justify-content: center;
        flex-wrap: wrap;
        margin: 20px;
    }
    .footer-content{
        align-items: center;
        margin-right: 0;
        margin-top: 30px;
    }
    .logos-container{
        margin-left: unset;
        .logo{
            
            height: 50px;
            margin-right: 20px;
        }
        a:last-child>.logo{
            margin-right: 0;
        }
    }
    .menu-items{
        display: none;
    }
    .credits{
        text-align: center;
    }
}
</style>
