<template>
    <div v-if="!onContactPage" class="contact">
        <div class="details">
            <h1>
                Contact
            </h1>
            <div class="contact-container">
                <div class="contact-info first-item">
                    <h1>Shauna Walters</h1>
                    <a href="tel:+1-310-775-1106">310.775.1106</a>
                    <a href="mailto:shauna@waltersplaxen.com">shauna@waltersplaxen.com</a>
                    <a target="_blank" href="https://www.instagram.com/shaunasellshomes/">@shaunasellshomes</a>
                </div>
                <div class="contact-info">
                    <h1>Nicole Plaxen</h1>
                    <a href="tel:+1-310-775-1106">310.710.7167</a>
                    <a href="mailto:shauna@waltersplaxen.com">nicole@waltersplaxen.com</a>
                    <a target="_blank" href="https://www.instagram.com/nicole.deleo.plaxen/">@nicole.deleo.plaxen</a>
                </div>
            </div>
            <hr class="mobile-only">
            <p class="address">
                8878 W Sunset Blvd. West Hollywood, CA 90069
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name:"FooterMenu",
    data(){
        return{
            menuItems: [
                {
                  label: "Home",
                  link: "/"
                },
                {
                    label: "About",
                    link: "/about"
                },
                {
                    label: "Properties",
                    link: "/properties"
                },
                {
                    label: "Sold",
                    link: "/sold"
                },
                {
                    label: "Press",
                    link: "/press"
                },
                {
                    label: "Team",
                    link: "/team"
                },
                {
                    label: "Contact",
                    link: "/contact"
                },
            ]
        }
    },
    computed: {
        onContactPage:function(){
            return this.$router.currentRoute.path === '/contact';
        },
    },
}
</script>

<style lang="scss" scoped>
.contact{
    background: url("/images/contact-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.details{
    display: flex;
    flex-direction: column;
    h1{
        text-transform: uppercase;
    }
}

.contact-container{
    display: flex;
}
.contact-info{
    margin-top: 40px;
    margin-bottom: 20px;
    padding: 0 50px 40px;
    h1{
        font-size: 50px;
        text-transform: uppercase;
    }
    a{
        display: block;
        text-transform: uppercase;
        text-decoration: none;
        color: #fff;
        margin-top:10px;
        font-family: poppins, sans-serif;
        font-weight: 275;
        font-size: 21px;
        line-height: 28px;
        letter-spacing: 0.01em;
        &:hover{
            opacity: 0.8;
        }
    }
}

.first-item{
    border-right: 1px solid #cba;
}
.address{
    font-weight: 275;
    font-size: 21px;
    line-height: 28px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
}


@media (max-width: 1000px) {
    .contact-container{
        flex-direction: column;
    }
    .first-item{
        border-right: none;
    }
    .contact-info{
        margin-top: 0;
        padding: 0;
    }
}

@media (max-width: $mobile) {
    .contact{
        height: 120vh;
    }
    .contact-info{
        margin-top: 10px;
        margin-bottom: 10px;
        a{
            font-size: 20px;
            line-height: 22px;
        }
    }
    hr{
        margin: 10px 20px;
    }
}


</style>