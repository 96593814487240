<template>
    <footer>
        <footer-contact />
        <FooterContent />
    </footer>  
</template>

<script>
import FooterContent from "./Footer/FooterContent"
import FooterContact from "./Footer/FooterContact"
export default {
    name: "Footer",
    components: {
        FooterContent,
        FooterContact
    }
}
</script>

<style lang="scss" scoped>

</style>